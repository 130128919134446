import React, { useContext } from "react";
import "./Home.css";
// import { Link } from "react-scroll";
import { AuthContext } from "../../Context/Context";
import { Link } from "react-scroll";

const Home = () => {
  const { t, i18n, showSide, setShowSide } = useContext(AuthContext);
  // const items = [
  //   {
  //     image: require("../../assets/image/ph-ya-1.png"),
  //     h2: "ECKL2007 Tools",
  //   },
  //   {
  //     image: require("../../assets/image/ph-ya-2.png"),
  //     h2: "ELAB20018 Tools",
  //   },
  //   {
  //     image: require("../../assets/image/ph-ya-3.png"),
  //     h2: "Tools",
  //   },
  //   {
  //     image: require("../../assets/image/ph-ya-4.png"),
  //     h2: "Tools",
  //   },
  //   {
  //     image: require("../../assets/image/ph-ya-5.png"),
  //     h2: "Tools",
  //   },
  //   {
  //     image: require("../../assets/image/ph-ya-6.png"),
  //     h2: "Tools",
  //   },
  // ];
  // const icons = [
  //   {
  //     icon: require("../../assets/image/icon-yy1.png"),
  //     title: "Test",
  //   },
  //   {
  //     icon: require("../../assets/image/icon-yy2.png"),
  //     title: "we",
  //   },
  //   {
  //     icon: require("../../assets/image/icon-yy3.png"),
  //     title: "3",
  //   },
  //   {
  //     icon: require("../../assets/image/icon-yy4.png"),
  //     title: "4",
  //   },
  //   {
  //     icon: require("../../assets/image/icon-yy5.png"),
  //     title: "5",
  //   },
  //   {
  //     icon: require("../../assets/image/icon-yy6.png"),
  //     title: "6",
  //   },
  // ];
  return (
    <section className="home">
      <div className="home_body">
        <h1>Welcome to Ragheed Profile</h1>
        <p>
          As a seasoned businessman with a passion for innovation and growth,
          Ragheed has established himself as a respected figure in business
          management . With +10 years of experience in the business world, he
          has developed a unique blend of strategic thinking, leadership skills,
          and entrepreneurial spirit. Throughout his career, Ragheed has been
          driven by a relentless pursuit of excellence, always seeking
          opportunities to drive business forward and make a lasting impact.
        </p>
        <div className="home_btn">
          <button className="btn-donate">
            <Link
              to="about"
              onClick={() => setShowSide(!showSide)}
              className={`nav-link`}
              spy={true}
              smooth={true}
              offset={-60}
              duration={800}
            >
              Business Vision
            </Link>
          </button>
        </div>
      </div>
      <div className="home_footer">
        <Link
          to="about"
          onClick={() => setShowSide("about")}
          className={`${showSide === "about" ? "active" : ""} nav-link`}
          spy={true}
          smooth={true}
          offset={-60}
          duration={800}
        >
          Scroll Down
        </Link>
      </div>
    </section>
  );
};

export default Home;
