import React, { useContext, useEffect, useState } from "react";
import Header from "./Component/Header/Header";
import Home from "./Page/Home/Home";
import Service from "./Page/Service/Service";
import Contact from "./Page/Contact/Contact";
import Footer from "./Page/Footer/Footer";
import About from "./Page/About/About";
import MyBusiness from "./Page/MyBusiness/MyBusiness";
import axios from "axios";
import { API } from "./Config/Api";
import { AuthContext } from "./Context/Context";
import { Link } from "react-scroll";
import video1 from "./assets/video/1009.mp4";

const Layout = () => {
  const [dataCat, setDataCat] = useState();
  const { showSide, setShowSide } = useContext(AuthContext);

  useEffect(() => {
    axios
      .get(`${API.About.client_about_get}`, {
        headers: {
          "Accept-Language": "en",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setDataCat(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Header />
      <Home />
      <div className="first_Sec">
        <p
          data-text={`"Get in touch with us today and let's grow your business together!"`}
        >
          "Get in touch with us today and let's grow your business together!"
        </p>
        <div className="home_btn">
          <button className="btn-donate">
            <Link
              to="MyBusiness"
              onClick={() => setShowSide(!showSide)}
              className={`nav-link`}
              spy={true}
              smooth={true}
              offset={-60}
              duration={800}
            >
              Business Vision
            </Link>
          </button>
        </div>
      </div>
      <About dataCat={dataCat} />
      <div className="Secs_Sec">
        <p
          data-text={`Contact us now and let's start building a brighter future for your business!`}
        >
          Contact us now and let's start building a brighter future for your
          business!
        </p>
        <div className="home_btn">
          <button className="btn-donate">
            <Link
              to="MyBusiness"
              onClick={() => setShowSide(!showSide)}
              className={`nav-link`}
              spy={true}
              smooth={true}
              offset={-60}
              duration={800}
            >
              Business Vision
            </Link>
          </button>
        </div>
      </div>
      <MyBusiness />
      <div className="video-responsive">
        <h1>Tribute video</h1>
        <p>
          {/* This video showcases my prestigious award ceremony at a renowned
          Russian organization, highlighting my outstanding achievements and
          contributions to the field. */}
          This video highlights my prestigious award ceremony from Damascus
          Governorate and Secretary of Damascus Branch of Baath Party in Russian
          Organization, and highlights my outstanding achievements and
          contributions in the field.
        </p>
        <video width="50%" controls>
          <source src={video1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <iframe
          width="853"
          height="480"
          // src={`https://www.youtube.com/embed/rokGy0huYEA`}
          src="./assets/video/1009.mp4"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        /> */}
      </div>
      <Contact dataCat={dataCat} />
      <Footer />
    </>
  );
};

export default Layout;
