import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-scroll";
import "./Header.css";
import { AuthContext } from "../../Context/Context";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { t, i18n, showSide, setShowSide } = useContext(AuthContext);
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`nav ${scrollPosition > 5 ? "scrollClassName" : ""}`}>
      <nav>
        <div className="logo">
          {/* <h1>Portfolio</h1> */}
          <div className="image">
            <img
              src={require("../../assets/image/Untitled-1LOGO-copy.png")}
              alt=""
            />
          </div>
          <i
            className="bx bx-menu-alt-right"
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          ></i>
        </div>
        <div className={`menu ${showMenu ? "active" : ""}`}>
          <ul>
            <li>
              <Link
                to="home"
                onClick={() => {
                  setShowSide(!showSide);
                  setShowMenu(!showMenu);
                }}
                className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
                spy={true}
                smooth={true}
                offset={0}
                duration={800}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="about"
                onClick={() => {
                  setShowMenu(!showMenu);
                  setShowSide(!showSide);
                }}
                className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="MyBusiness"
                onClick={() => {
                  setShowMenu(!showMenu);
                  setShowSide(!showSide);
                }}
                className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
              >
                MyBusiness
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                onClick={() => {
                  setShowMenu(!showMenu);
                  setShowSide(!showSide);
                }}
                className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
