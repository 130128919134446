import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer section_container">
      Copyright © 2024 <span style={{fontWeight:"700",textTransform:"uppercase"}}>Ragheed </span>. All rights reserved.
    </div>
  );
};

export default Footer;
