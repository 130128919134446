import React, { useContext } from "react";
import "./About.css";
import { AuthContext } from "../../Context/Context";
import { Link } from "react-scroll";

const About = ({ dataCat }) => {
  const { t, i18n, showSide, setShowSide } = useContext(AuthContext);
  return (
    <section className="about">
      <div className="section_container">
        <div
          style={{
            gap: i18n.language === "ar" ? "0 20px" : "",
          }}
          className={`section_header ${i18n.language === "ar" ? "ar" : ""}`}
        >
          <h2
            style={{
              fontFamily: i18n.language === "ar" ? "Cairo" : "",
            }}
          >
            {t("menu.About")}
          </h2>
        </div>
        <div
          className="section_subheader"
          style={{
            letterSpacing: i18n.language === "ar" ? "0" : "",
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
          }}
        >
          "Business oriented, dedicated to making a difference in every
          project."
        </div>
        <div className="about_body">
          <div className="about_body_l">
            <img src={require("../../assets/image/Ragheed.jpeg")} />
          </div>
          <div className="about_body_r">
            {/* <h1>Ragheed</h1> */}
            <p>{dataCat?.bio}</p>
            <div
              className="home_btn"
              style={{
                position: "relative",
                zIndex: "10",
              }}
            >
              <button className="btn-donate">
                <Link
                  to="MyBusiness"
                  onClick={() => setShowSide(!showSide)}
                  className={`nav-link`}
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={800}
                >
                  Read More
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
