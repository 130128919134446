import React, { useContext, useEffect, useState } from "react";
import "./Contact.css";
import { AuthContext } from "../../Context/Context";
import Toast from "../../Component/Sweetalert/Sweetalert";
import { API } from "../../Config/Api";
import axios from "axios";

const Contact = ({ dataCat }) => {
  const { t, i18n } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("full_name", subject);
    formData.append("question", description);
    axios
      .post(API.Contact.client_contact_add, formData, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          Toast.fire({
            text: `${res.data.message}`,
            icon: "success",
          });
          setEmail("");
          setSubject("");
          setDescription("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <section className="contact us">
      <div className="section_container">
        <div className="contact_body">
          <div className="contact_b_l">
            <h2>Contact Us .</h2>
            <p>Contact us now and get the help you need</p>
            <div className="infor">
              <div className="item map">
                <i className="bx bx-map"></i>
                <label>{dataCat?.address}</label>
              </div>
              <div className="item phone">
                <i className="bx bx-phone"></i>
                <label>{dataCat?.phone_mobile}</label>
              </div>
              <div className="item email">
                <i className="bx bxl-gmail"></i>
                <label>{dataCat?.email}</label>
              </div>
            </div>
            <div
              className="social_media"
              style={{
                justifyContent: i18n.language === "ar" ? "flex-end" : "",
              }}
            >
              <div className="children1">
                <a
                  href={dataCat?.instagram}
                  target="_blank"
                  className="Btn Btn_insta"
                >
                  <span className="svgContainer">
                    <i className="bx bxl-instagram svgIcon"></i>
                  </span>
                  <span className="BG"></span>
                </a>
              </div>
              <div className="children2">
                <a
                  href={dataCat?.facebook}
                  target="_blank"
                  className="Btn Btn_face"
                >
                  <span className="svgContainer">
                    <i className="bx bxl-facebook svgIcon"></i>
                  </span>
                  <span className="BG"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="contact_b_r">
            <form onSubmit={handleSubmit}>
              <div className="child_group">
                <input
                  required
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="email"
                  placeholder="Your Email"
                />
                <input
                  required
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                  type="text"
                  placeholder="Full Name"
                />
              </div>
              <div className="child_group">
                <textarea
                  required
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  rows={8}
                  type="text"
                  minLength={16}
                  placeholder="Question"
                />
              </div>
              <div className="child_group">
                <button>Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
